import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiService } from 'src/app/_services/clienti.service';
import { ReportService } from 'src/app/_services/report.service';
import { Config } from 'src/app/_settings/config';

@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent implements OnInit {
  
  apiImgUrl = Config.apiImgUrl;

  idReport: any;

  idCliente: string;
  nomeCliente: string = '';
  cognomeCliente: string = '';
  emailCliente: string = '';
  telefonoCliente: string = '';
  noteCliente: string = '';
  dataIngresso: any;
  altro: string = '';
  /*alimentatore: boolean = false;
  borsa: boolean = false;
  cavoAlimentazione: boolean = false;
  cavoUsb: boolean = false;
  chiavettaUsb: boolean = false;*/
  alimentatore: number;
  borsa: number;
  cavoAlimentazione: number;
  cavoUsb: number;
  chiavettaUsb: number;
  numRifInt: any;
  password: string = '';
  problema: string = '';
  tipoMacchina: string = '';
  varie: string = '';
  ritirato: any;
  dataConsegna: any;
  preventivo: string = '';
  //tecnico: boolean = false;
  tecnico: number;
  slotUno: any;
  //slotDue: any;
  //slot1: any;
  //slot23: any;
  immagine_uno: string = '';
  immagine_due: string = '';
  immagine_tre: string = '';
  immagine_quattro: string = '';

  reportList: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private clientiService: ClientiService,
    private fb: UntypedFormBuilder,
    private reportService: ReportService
  ) {

    this.activatedRoute.queryParams
    .subscribe(params => {
      console.log(params);
      this.idReport = params.idReport;
      this.getDatiReport(this.idReport);

    });
  }

  getDatiReport(id) {

    this.reportService.visualizzaReport(id).subscribe((res: any) => {
      console.log(res);
      //console.log(res.result[0]);
      this.idCliente = res.result[0].id_cliente;
      this.nomeCliente = res.result[0].nome_cliente;
      this.cognomeCliente = res.result[0].cognome_cliente;
      this.emailCliente = res.result[0].email_cliente;
      this.telefonoCliente = res.result[0].telefono_cliente;
      this.noteCliente = res.result[0].note_cliente;
      this.dataIngresso = res.result[0].data_ingresso;
      this.alimentatore = res.result[0].acc_alimentatore;
      this.altro = res.result[0].acc_altro;
      this.borsa = res.result[0].acc_borsa;
      this.cavoAlimentazione = res.result[0].acc_cavo_alim;
      this.cavoUsb = res.result[0].acc_cavo_usb;
      this.chiavettaUsb = res.result[0].acc_chiavetta_usb;
      this.numRifInt = res.result[0].num_rif;
      this.password = res.result[0].password;
      this.problema = res.result[0].problema;
      this.tipoMacchina = res.result[0].tipo_macchina;
      this.varie = res.result[0].acc_varie;
      this.dataConsegna = res.result[0].data_consegna;
      this.ritirato = res.result[0].ritirato;
      this.preventivo = res.result[0].preventivo;
      this.tecnico = res.result[0].tecnico;
      this.slotUno = res.result[0].slot;
      //this.slotDue = res.slots.slotDue;
      //this.slot1 = res.slots.slot1;
      //this.slot23 = res.slots.slot23;
      if (res.result[0].immagine_uno == null) {
        this.immagine_uno = null;
      } else {
        this.immagine_uno = this.apiImgUrl + res.result[0].immagine_uno;
      }
      if (res.result[0].immagine_due == null) {
        this.immagine_due = null;
      } else {
        this.immagine_due = this.apiImgUrl + res.result[0].immagine_due;
      }
      if (res.result[0].immagine_tre == null) {
        this.immagine_tre = null;
      } else {
        this.immagine_tre = this.apiImgUrl + res.result[0].immagine_tre;
      }
      if (res.result[0].immagine_quattro == null) {
        this.immagine_quattro = null;
      } else {
        this.immagine_quattro = this.apiImgUrl + res.result[0].immagine_quattro;
      }

    });
  }

  ngOnInit(): void {
  }

}
