import { Component, OnInit, ViewChild } from '@angular/core';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiService } from 'src/app/_services/clienti.service';

import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { HttpClient } from '@angular/common/http';
import { ClientiAutocompleteModel } from 'src/app/_model/clientiautocomplete.model';
import { Config } from '../../_settings/config';
import { ReportService } from 'src/app/_services/report.service';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  baseUrl:string = Config.apiUrl;

  /* autocomplete params */
  keyword = 'nome';


  data: Array<ClientiAutocompleteModel> = [];
  errorMsg: string = 'Nessun risultato';
  isLoadingResult: boolean;

  idCliente: number;


  @ViewChild(DataTableDirective, {static: true })

  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    ordering: false,
    responsive: true,
    "language": {
      "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Italian.json"
    }
  }
  dtTrigger: Subject<any> = new Subject();

  listaReport: any;

  closeResult: string;
  modalOptions:NgbModalOptions;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public reportService: ReportService,
    private modalService: NgbModal,
    private http: HttpClient,
  ) {
    this.getListaReport();
  }

  ngOnInit() {
  }

  getListaReport() {
    this.reportService.getListReport().subscribe((res: any) => {
      console.log(res);
      if (res.success == true) {
        this.listaReport = res.result;
        console.log(res.result);
      } else {
        console.log(res.result);
      }

      this.dtTrigger.next();

    }, (err)=> {
      console.log(err);

    });
  }

  modificaReport(id, idcli) {
    /*this.clientiService.getCliente(id).subscribe((res: any) => {
      console.log(res);
    });*/
    this.router.navigate(['report-mod'],  { queryParams: { idReport: id, idCliente: idcli } });
  }

  eliminaReport(id, nome, cognome) {

    /**/

    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.my_modal_title = 'Elimina scheda cliente';
    modalRef.componentInstance.my_modal_content = 'Sicuro di voler eliminare il cliente ' + nome  + '?';
    modalRef.componentInstance.my_modal_content_id = id;
    modalRef.componentInstance.my_modal_type_action = 'delReport';

  }

  nuovoReport(id) {
    console.log("ID " + id);
    this.router.navigate(['report-manage'],  { queryParams: { idCliente: id, modo: 'add_report' } });
  }

  reportVisualizza(id) {

    this.router.navigate(['report-view'],  { queryParams: { idReport: id } });

  }

  reportConsegnato(id) {
    this.reportService.consegnaReport(id).subscribe((res: any) => {
      console.log(res);

      if (res.result == 'reportConsegnato') {
        //this.router.navigate(['report']);
        window.location.reload();
      } else {
        alert('Errore pulsante consegna');
      }

    });
  }

  /** autocomplete */

  getServerResponse(event) {

    this.isLoadingResult = true;

    this.http.get(this.baseUrl + 'autocomplete.php?search=' + event)
      .subscribe((res: any) => {
        console.log(res);
        if (res == undefined) {
          this.data = [];
          this.errorMsg = 'Nessun risultato';
        } else {
          this.data = res.result;
        }

        this.isLoadingResult = false;
      });
  }

  searchCleared() {
    console.log('searchCleared');
    this.data = [];
  }

  selectEvent(item) {
    // do something with selected item
    console.log(JSON.stringify(item));
    this.idCliente = item.idCliente;
    this.router.navigate(['report-manage'],  { queryParams: { idCliente: this.idCliente, modo: 'add_report' } });
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused(e) {
    // do something when input is focused
  }

  printDiv(id) {
    this.reportService.printDiv(id);
  }

  uploadPhoto(idReport, idCliente) {
    this.router.navigate(['report-upload-photo'],  { queryParams: { idReport: idReport, idCliente: idCliente } });
  }

}
