import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/_services/report.service';
import { Config } from 'src/app/_settings/config';

@Component({
  selector: 'app-report-upload-photo',
  templateUrl: './report-upload-photo.component.html',
  styleUrls: ['./report-upload-photo.component.scss']
})
export class ReportUploadPhotoComponent {

  apiImgUrl = Config.apiImgUrl;
  apiUrl = Config.apiUrl;

  idReport: any;
  idCliente: any;
  nomeCliente: string = '';
  cognomeCliente: string = '';
  tipoMacchina: string = '';
  immagine_uno: string = '';
  immagine_due: string = '';
  immagine_tre: string = '';
  immagine_quattro: string = '';

  /*------------------------------------------
  --------------------------------------------
  Declare Form
  --------------------------------------------
  --------------------------------------------*/
  myForm_uno = new FormGroup({
    idCliente:  new FormControl(''),
    idReport:  new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  myForm_due = new FormGroup({
    idCliente:  new FormControl(''),
    idReport:  new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  myForm_tre = new FormGroup({
    idCliente:  new FormControl(''),
    idReport:  new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  myForm_quattro = new FormGroup({
    idCliente:  new FormControl(''),
    idReport:  new FormControl(''),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });
  
  /*------------------------------------------
  --------------------------------------------
  Created constructor
  --------------------------------------------
  --------------------------------------------*/
  constructor(
    private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private reportService: ReportService
  ) {
    

    this.activatedRoute.queryParams
    .subscribe(params => {
      console.log(params);
      this.idCliente = params.idCliente;
      this.idReport = params.idReport;
    });

    this.getDatiReport(this.idReport);
  }
  
  /**
   * Write code on Method
   *
   * @return response()
   */
  get f_uno(){
    return this.myForm_uno.controls;
  }
  get f_due(){
    return this.myForm_due.controls;
  }
  get f_tre(){
    return this.myForm_tre.controls;
  }
  get f_quattro(){
    return this.myForm_quattro.controls;
  }
  
  /**
   * Write code on Method
   *
   * @return response()
   */
  onFileChange_uno(event:any) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm_uno.patchValue({
        fileSource: file
      });
    }
  } 
  onFileChange_due(event:any) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm_due.patchValue({
        fileSource: file
      });
    }
  } 
  onFileChange_tre(event:any) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm_tre.patchValue({
        fileSource: file
      });
    }
  } 
  onFileChange_quattro(event:any) {
  
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm_quattro.patchValue({
        fileSource: file
      });
    }
  } 
  
  /**
   * Write code on Method
   *
   * @return response()
   */
  submit_uno(){
    const formData = new FormData();
    formData.append('file', this.myForm_uno.get('fileSource')?.value);
    formData.append('idCliente', this.idCliente);
    formData.append('idReport', this.idReport);
    formData.append('numero', 'uno');
     
    this.http.post(this.apiUrl + '/report_api_upload.php', formData)
      .subscribe(res => {
        console.log('Uploaded Successfully.');
        window.location.reload();
      });
  }
  
  submit_due(){
    const formData = new FormData();
    formData.append('file', this.myForm_due.get('fileSource')?.value);
    formData.append('idCliente', this.idCliente);
    formData.append('idReport', this.idReport);
    formData.append('numero', 'due');
     
    this.http.post(this.apiUrl + '/report_api_upload.php', formData)
      .subscribe(res => {
        console.log('Uploaded Successfully.');
        window.location.reload();
      });
  }
  
  submit_tre(){
    const formData = new FormData();
    formData.append('file', this.myForm_tre.get('fileSource')?.value);
    formData.append('idCliente', this.idCliente);
    formData.append('idReport', this.idReport);
    formData.append('numero', 'tre');
     
    this.http.post(this.apiUrl + '/report_api_upload.php', formData)
      .subscribe(res => {
        console.log('Uploaded Successfully.');
        window.location.reload();
      });
  }
  
  submit_quattro(){
    const formData = new FormData();
    formData.append('file', this.myForm_quattro.get('fileSource')?.value);
    formData.append('idCliente', this.idCliente);
    formData.append('idReport', this.idReport);
    formData.append('numero', 'quattro');
     
    this.http.post(this.apiUrl + '/report_api_upload.php', formData)
      .subscribe(res => {
        console.log('Uploaded Successfully.');
        window.location.reload();
      });
  }

  getDatiReport(id) {

    this.reportService.visualizzaReport(id).subscribe((res: any) => {
      console.log(res);
      this.nomeCliente = res.result[0].nome_cliente;
      this.cognomeCliente = res.result[0].cognome_cliente;
      this.tipoMacchina = res.result[0].tipo_macchina;
      if (res.result[0].immagine_uno == null) {
        this.immagine_uno = null;
      } else {
        this.immagine_uno = this.apiImgUrl + res.result[0].immagine_uno;
      }
      if (res.result[0].immagine_due == null) {
        this.immagine_due = null;
      } else {
        this.immagine_due = this.apiImgUrl + res.result[0].immagine_due;
      }
      if (res.result[0].immagine_tre == null) {
        this.immagine_tre = null;
      } else {
        this.immagine_tre = this.apiImgUrl + res.result[0].immagine_tre;
      }
      if (res.result[0].immagine_quattro == null) {
        this.immagine_quattro = null;
      } else {
        this.immagine_quattro = this.apiImgUrl + res.result[0].immagine_quattro;
      }

    });
  }
}
