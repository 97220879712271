import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReportService } from 'src/app/_services/report.service';
import { formatDate } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { LoginService } from 'src/app/_services/login.service';


@Component({
  selector: 'app-nuovo-operatore',
  templateUrl: './nuovo-operatore.component.html',
  styleUrls: ['./nuovo-operatore.component.css']
})
export class NuovoOperatoreComponent implements OnInit {

  email: string = '';
  password: string = '';

  result: string = '';
  success: string = '';

  registerForm: UntypedFormGroup;
  // Flag to check if form submitted by user to handle error messages
  isFormSubmitted = false;


  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private loginService: LoginService,
    private fb: UntypedFormBuilder,
    private reportService: ReportService
  ) {
  }

  ngOnInit() {
    this.registerForm = this.fb.group({
      email: [''],
      password: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }

  onSubmit() {

    console.log(this.registerForm.value);

    this.isFormSubmitted = true;

    // Return if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    console.log('Submit', this.registerForm.value);

    

    let body = {
      chk: 'add_utente',
      email: this.registerForm.value.email,
      password: this.registerForm.value.password
    }
      
    return new Promise(resolve => {

      this.loginService.userregistration('add_utente', this.registerForm.value.email, this.registerForm.value.password).subscribe((res: any) => {
        console.log(res);
        this.result = res.result;
        this.success = res.success;
        
        if (res.success == true) {
          console.log(this.registerForm.value);
          // CREARE EVENTO CALENDAR
          this.registerForm.reset();
        } else {
          console.log(res.result);
        }
      }, (err)=> {
        console.log(err);
      });
    });

  }

}
