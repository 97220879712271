import { HttpClient } from '@angular/common/http';
import { Component, ComponentFactoryResolver, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiService } from 'src/app/_services/clienti.service';
import { ClienteModel } from 'src/app/_model/clienti.model';
import { ReportService } from 'src/app/_services/report.service';
import { formatDate } from '@angular/common';
import { LOCALE_ID } from '@angular/core';

@Component({
  selector: 'app-report-mod',
  templateUrl: './report-mod.component.html',
  styleUrls: ['./report-mod.component.css']
})
export class ReportModComponent implements OnInit {

  todayDate = new Date().toISOString();
  localID: string;

  idReport: any;

  idCliente: number;
  nomeCliente: string = '';
  cognomeCliente: string = '';
  emailCliente: string = '';
  telefonoCliente: string = '';
  noteCliente: string = '';
  dataIng: any;
  alimentatore: boolean = false;
  altro: string = '';
  borsa: boolean = false;
  cavoAlimentazione: boolean = false;
  cavoUsb: boolean = false;
  chiavettaUsb: boolean = false;
  numRifInt: any;
  password: string = '';
  problema: string = '';
  tipoMacchina: string = '';
  varie: string = '';
  dataConsegna: any;
  oraConsegna: any;
  ritirato: any;
  preventivo: string = '';
  tecnico: boolean = false;
  pronto: string = 'no';
  idEvento: any;

  alimChk: boolean = false;
  borChk: boolean = false;
  cavoalChk: boolean = false;
  cavouChk: boolean = false;
  chiaveChk: boolean = false;
  slotUno: any;
 // slotDue: any;
  //slot1: any; //slot più grande da selezionare manualmente
  //slot23: any; //slot più grande da selezionare manualmente

  //slotUnoTemp: any; // prende il valore dello slot uno selezionato per disabilitarlo nella scelta dello slot due
  //slotDueTemp: any;
  //slot1Temp: any;
  //slot23Temp: any;

  //slotUnoOld: any; // Memorizzo il vechio valore dello slot per confrontarlo nel file php
  //slotDueOld: any;
  //slot1Old: any;
  //slot23Old: any;


  modoInvio: string; // modifica o aggiungi report

  //datiReport = new ReportModel;

  datiReport: any;

  reportForm: UntypedFormGroup;
  addUserForm: UntypedFormGroup;
  // Flag to check if form submitted by user to handle error messages
  isFormSubmitted = false;
  submittedForm = false;

  slotList: any;


  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private clientiService: ClientiService,
    private fb: UntypedFormBuilder,
    private reportService: ReportService,
    @Inject( LOCALE_ID ) localID: string
  ) {

    this.localID = localID;

    this.activatedRoute.queryParams
    .subscribe(params => {
      console.log(params);
      this.modoInvio = params.modo;
      this.idCliente = params.idCliente;
      this.idReport = params.idReport;
      this.getDatiCliente(this.idCliente);

      //this.dataIng = formatDate(this.todayDate,'yyyy-MM-dd', this.localID);

    });


  }

  ngOnInit() {
    this.listaSlot();
    this.getDatiReport(this.idReport);
    //console.log(this.nomeCliente);
    this.reportForm = this.fb.group({
      dataIngresso: [this.dataIng],
      numRifInt: [this.numRifInt],
      password: [this.password],
      tipoMacchina: [this.tipoMacchina],
      alimentatore: [this.alimentatore],
      borsa: [this.borsa],
      cavoAlimentazione: [this.cavoAlimentazione],
      cavoUsb: [this.cavoUsb],
      chiavettaUsb: [this.chiavettaUsb],
      altro: [this.altro],
      varie: [this.varie],
      problema: [this.problema],
      dataConsegna: [this.dataConsegna],
      oraConsegna: [this.oraConsegna],
      ritirato: [this.ritirato],
      preventivo: [this.preventivo],
      tecnico: [this.tecnico],
      slotUno: [this.slotUno],
      //slotDue: [this.slotDue],
      //slot1: [this.slot1],
      //slot23: [this.slot23]
    });
    console.log(this.reportForm.value);
  }

  getDatiCliente(id) {
    this.clientiService.getCliente(id).subscribe((res: any) => {
      //console.log(res.result);
      //console.log(this.idCliente);
      this.nomeCliente = res.result.nome_cliente;
      this.cognomeCliente = res.result.cognome_cliente;
      this.telefonoCliente = res.result.telefono_cliente;
      this.emailCliente = res.result.email_cliente;
      this.noteCliente = res.result.note_cliente;

      // Defining Form Controlls with initial value and validations for each form control



      //console.log(this.reportForm);
    });
  }


  // 192.168.178.82 IP SERVER

  getDatiReport(idReport) {

    let s1, s23;

    this.reportService.getSingleReport(idReport).subscribe((res: any) => {
console.log(res);
      if (res.result[0].acc_alimentatore == 1) {
        this.alimChk = true;
      }

      if (res.result[0].acc_borsa == 1) {
        this.borChk = true;
      }

      if (res.result[0].acc_cavo_alim == 1) {
        this.cavoalChk = true;
      }

      if (res.result[0].acc_cavo_usb == 1) {
        this.cavouChk = true;
      }

      if (res.result[0].acc_chiavetta_usb == 1) {
        this.chiaveChk = true;
      }

      /*if (res.slot.slot1 == 1) {
        s1 = true;
      } else {
        s1 = false;
      }
      
      if (res.slot.slot23 == 23) {
        s23 = true;
      } else {
        s23 = false;
      }*/

      this.idReport = res.result[0].id_report;
      this.idCliente = res.result[0].id_cliente;
      this.nomeCliente = res.result[0].nome_cliente;
      this.cognomeCliente = res.result[0].cognome_cliente;
      this.emailCliente = res.result[0].email_cliente;
      this.telefonoCliente = res.result[0].telefono_cliente;
      this.noteCliente = res.result[0].note_cliente;
      this.dataIng = res.result[0].data_ingresso;
      this.alimentatore = this.alimChk;
      this.altro = res.result[0].acc_altro;
      this.borsa = this.borChk;
      this.cavoAlimentazione = this.cavoalChk;
      this.cavoUsb = this.cavouChk;
      this.chiavettaUsb = this.chiaveChk;
      this.numRifInt = res.result[0].num_rif;
      this.password = res.result[0].password;
      this.problema = res.result[0].problema;
      this.tipoMacchina = res.result[0].tipo_macchina;
      this.varie = res.result[0].acc_varie;
      this.dataConsegna = res.result[0].data_consegna;
      this.oraConsegna = res.result[0].ora_consegna;
      this.ritirato = res.result[0].ritirato;
      this.preventivo = res.result[0].preventivo;
      this.tecnico = res.result[0].tecnico;
      this.pronto = res.result[0].pronto;
      this.idEvento = res.result[0].id_evento;
      this.slotUno = res.result[0].slot;
      //this.slotDue = res.slot.slotDue;
      //this.slot1 = s1;
      //this.slot23 = s23;
      //this.slotUnoTemp = res.slot.slotUno;
      //this.slotDueTemp = res.slot.slotDue;
      //this.slot1Temp = s1;
      //this.slot23Temp = s23;
      //this.slotUnoOld = res.slot.slotUno;
      //this.slotDueOld = res.slot.slotDue;
      //this.slot1Old = s1;
      //this.slot23Old = s23;

      //console.log('UNO ' + this.slotUno + ' ' + this.slotDue);



    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.reportForm.controls; }

  onSubmit() {
    //let f = f();
    console.log(this.f);

    console.log(this.alimentatore);
    this.submittedForm = true;

    this.isFormSubmitted = true;

    // Return if form is invalid
    if (this.reportForm.invalid) {
      return;
    }
    console.log('Submit', this.reportForm.value);

    let body = {
      chk: 'mod_report',
      idReport: this.idReport,
      idCliente: this.idCliente,
      dataIngresso: this.dataIng,
      alimentatore: this.alimentatore,
      altro: this.reportForm.value.altro,
      borsa: this.reportForm.value.borsa,
      cavoAlimentazione: this.reportForm.value.cavoAlimentazione,
      cavoUsb: this.reportForm.value.cavoUsb,
      chiavettaUsb: this.reportForm.value.chiavettaUsb,
      numRifInt: this.reportForm.value.numRifInt,
      password: this.reportForm.value.password,
      problema: this.reportForm.value.problema,
      tipoMacchina: this.reportForm.value.tipoMacchina,
      varie: this.reportForm.value.varie,
      dataConsegna: this.dataConsegna,
      oraConsegna: this.oraConsegna,
      ritirato: this.ritirato,
      preventivo: this.preventivo,
      tecnico: this.tecnico,
      slotUno: this.reportForm.value.slotUno,
      //slotDue: this.reportForm.value.slotDue,
      //slot1: this.reportForm.value.slot1,
      //slot23: this.reportForm.value.slot23,
      //slotUnoOld: this.slotUnoOld,
      //slotDueOld: this.slotDueOld,
      //slot1Old: this.slot1Old,
      //slot23Old: this.slot23Old
    }
    console.log(body);
    // Return if form is invalid
    if (this.reportForm.invalid) {
      console.log("INVALID");
      return;
    }

    return new Promise(resolve => {

      this.reportService.manageReport(body).subscribe((res: any) => {
        console.log(body);

        if (res.success == true) {
          console.log(this.reportForm.value);
          // CREARE EVENTO CALENDAR
          //
          //this.printDiv(this.reportForm.value, this.nomeCliente, this.cognomeCliente,this.telefonoCliente, this.emailCliente);
          this.modEvent(this.reportForm.value, this.idReport);
        } else {
          console.log(res.result);
        }
      }, (err)=> {
        console.log(err);
      });
    });

  }

  modEvent(values, idReport) {
    console.log(values);
    //values.dataIngresso = dataIng;
    this.reportService.modCalendar(this.nomeCliente,this.cognomeCliente,values.tipoMacchina,values.dataConsegna, values.oraConsegna, this.idEvento, idReport).subscribe((res:any) => {
      console.log(res);
      //this.printDiv(values, this.nomeCliente, this.cognomeCliente,this.telefonoCliente, this.emailCliente);
      this.router.navigate(['/report']);
    },
    error => {
      console.log(error);
    });
  }

  reportPronto(id) {
    console.log(id);
    this.reportService.reportPronto(this.idReport).subscribe((res: any) => {
      window.location.reload();
    });
  }

  printDiv(values, nome, cognome, telefono, email) {
console.log(values);
console.log('DUE ' + this.dataIng);
    let dataIng = formatDate(this.dataIng,'dd/MM/yyyy', this.localID);
    ///let data_temp = this.dataIng.split('-');
    //let dataIng = data_temp[2] + '/' + data_temp[1] + '/' + data_temp[0];
    let alimentatore, borsa, cavoAli, cavoUsb, chiavettaUsb;
    if (values.alimentatore == true) { alimentatore = 'checked'} else { alimentatore = '' }
    if (values.borsa == true) { borsa = 'checked'} else { borsa = '' }
    if (values.cavoAlimentazione == true) { cavoAli = 'checked'} else { cavoAli = '' }
    if (values.cavoUsb == true) { cavoUsb = 'checked'} else { cavoUsb = '' }
    if (values.chiavettaUsb == true) { chiavettaUsb = 'checked'} else { chiavettaUsb = '' }
    let divToPrint = document.getElementById('report-print');
    let newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write(`
    <html>
    <head>
    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css" media="print"/>
    <style>
      body {
        margin: 10px !important;
        padding: 10px !important;
      }
      @page { size: A4 portrait }
    </style>
    </head>
    <body onload="window.print()" class="A5 portrait">
      <div class="form-group row">
          <div class="col-3">
              <label class="form-control-label" for="input-nome">Data ingresso</label><br>
              <span>${dataIng}</span>
          </div>
          <div class="col-6 text-center" style="border: 1px solid black;">
              <strong>ECORIGENERA</strong>
              <br>
              SCHEDA DI INGRESSO/RIPARAZIONE
          </div>
          <div class="col-3">
              &nbsp;
          </div>
      </div>
      <!-- Name control -->
      <div class="form-group row">

          <div class="col-2">
              <label class="col-form-label">Num. Rif. Interno</label><br>
              <input type="text" formControlName="numRifInt" class="form-control" value="${values.numRifInt}">
          </div>

          <div class="col-5">
              <label class="col-form-label">Password</label><br>
              <input type="text" formControlName="password" class="form-control" value="${values.password}">
          </div>

          <div class="col-5">
              <label class="col-form-label">Tipo macchina</label><br>
              <input type="text" formControlName="tipoMacchina" class="form-control" value="${values.tipoMacchina}">
          </div>
      </div>

      <div class="form-group row">
          <div class="col-12">
              <div class="row">
                  <div class="col-12 text-center">
                      <strong>CLIENTE</strong>
                  </div>
              </div>
              <br>
              <div class="row">
                  <div class="col-3">
                      <div class="form-group">
                      <label class="form-control-label" for="input-nome">Nome</label><br>
                      <input type="text" readonly id="input-nome" class="form-control form-control-alternative" formControlName="nomeCliente" name="nomeCliente" value="${nome}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-cognome">Cognome</label><br>
                          <input type="text" readonly id="input-cognome" class="form-control form-control-alternative" formControlName="cognomeCliente" value="${cognome}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-email">Email</label><br>
                          <input type="email" readonly id="input-email" class="form-control form-control-alternative" formControlName="emailCliente" value="${email}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-telefono">Telefono</label><br>
                          <input type="text" readonly id="input-telefono" class="form-control form-control-alternative" formControlName="telefonoCliente" value="${telefono}">
                      </div>
                      </div>
              </div>
          </div>
      </div>

      <div class="form-group row">
          <div class="col-10 offset-1 text-center" style="border: 2px solid black;">
              <div class="row">
                  <div class="col-12 text-center">
                      <strong>ACCESSORI</strong>
                  </div>
              </div>
              <br>
              <div class="row justify-content-center">
                  <div class="col-2">
                  <div class="form-group">
                      Alimentatore<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="alimentatore" ${alimentatore}>
                  </div>
                  </div>
                  <div class="col-2">
                  <div class="form-group">
                      Borsa&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="borsa" ${borsa}>
                  </div>
                  </div>
                  <div class="col-3">
                  <div class="form-group">
                      Cavo alimentazione&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoAlimentazione" ${cavoAli}>
                  </div>
                  </div>
                  <div class="col-2">
                  <div class="form-group">
                      Cavo USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoUsb" ${cavoUsb}>
                  </div>
                  </div>
                  <div class="col-3">
                  <div class="form-group">
                      Chiavetta USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="chiavettaUsb" ${chiavettaUsb}>
                  </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                  <div class="form-group">
                      <label class="form-control-label" for="input-altro">Altro</label>
                      <input type="text" id="input-altro" class="form-control form-control-alternative" formControlName="altro" value="${values.altro}">
                  </div>
                  </div>
                  <div class="col-6">
                  <div class="form-group">
                      <label class="form-control-label" for="input-varie">Varie</label>
                      <input type="text" id="input-varie" class="form-control form-control-alternative" formControlName="varie" value="${values.varie}">
                  </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="form-group row">
          <div class="col-12">
          <div class="form-group">
              <label class="form-control-label" for="input-problema">Problema riscontrato</label>
              <textarea class="form-control" formControlName="problema" rows="3">${values.problema}</textarea>
          </div>
          </div>
      </div>
    </body></html>`);
    newWin.document.close();


    //
    setTimeout(function() {
      //window.location.reload();
      console.log("OK");
      newWin.close();
    }, 30);
  }

  listaSlot() {
    this.reportService.listaSlot().subscribe((res: any) => {
      console.log("SLOT: ",res);
      this.slotList = res.result;
    })
  }

}
