import { Component, OnInit, ViewChild } from '@angular/core';

import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-bs4';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiService } from 'src/app/_services/clienti.service';

import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';

@Component({
  selector: 'app-clienti',
  templateUrl: './clienti.component.html',
  styleUrls: ['./clienti.component.css']
})
export class ClientiComponent implements OnInit {
  

  @ViewChild(DataTableDirective, {static: true })
  
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {
    responsive: true,
    "order": [[ 0, "desc" ]],
    "columnDefs": [
      {
          "targets": [ 0 ],
          "visible": false
      }
    ],
    "language": {
      "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Italian.json"
    }
  }
  dtTrigger: Subject<any> = new Subject();

  listaClienti: any;

  closeResult: string;
  modalOptions:NgbModalOptions;

  constructor(
    private activatedRoute: ActivatedRoute,
    public router: Router,
    public clientiService: ClientiService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getListaClienti();
  }

  getListaClienti() {
    this.clientiService.getListClienti().subscribe((res: any) => {
      console.log(res);
      if (res.success == true) {
        this.listaClienti = res.result.reverse();
        console.log(res.result);
      } else {
        console.log(res.result);
      }

      this.dtTrigger.next();

    }, (err)=> {
      console.log(err);
    
    });
  }

  modificaCliente(id) {
    /*this.clientiService.getCliente(id).subscribe((res: any) => {
      console.log(res);
    });*/
    this.router.navigate(['user-profile'],  { queryParams: { idCliente: id } });
  }

  eliminaCliente(id, nome, cognome) {

    /*FUNZIONE NON UTILIZZATA PERCHE' SE ELIMINA IL CLIENTE LO STORICO DEI REPORT NON HA I DATI AL SUO INTERNO PERCHè LEGATO AI DATI DEL CLIENTE MEMORIZZATI NEL DB */

    const modalRef = this.modalService.open(ModalComponent);
    modalRef.componentInstance.my_modal_title = 'Elimina scheda cliente';
    modalRef.componentInstance.my_modal_content = 'Sicuro di voler eliminare il cliente ' + nome + ' ' + cognome + '?';
    modalRef.componentInstance.my_modal_content_id = id;
    
  }

  nuovoCliente() {
    this.router.navigate(['user-profile']);
  }

}
