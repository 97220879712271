import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { DataTablesModule } from 'angular-datatables';
import { ClientiComponent } from './pages/clienti/clienti.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { ReportComponent } from './pages/report/report.component';
import { ReportManageComponent } from './pages/report-manage/report-manage.component';

import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { ReportViewComponent } from './pages/report-view/report-view.component';
import { ReportModComponent } from './pages/report-mod/report-mod.component';
import { NuovoOperatoreComponent } from './pages/nuovo-operatore/nuovo-operatore.component';
import { UserProfileModComponent } from './pages/user-profile-mod/user-profile-mod.component';
import { PrintReportComponent } from './print-report/print-report.component';
import { PrintReportLayoutComponent } from './print-report-layout/print-report-layout.component';
import { NgxPrintModule } from 'ngx-print';
import { ReportUploadPhotoComponent } from './pages/report-upload-photo/report-upload-photo.component';



@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    DataTablesModule,
    AutocompleteLibModule,
    NgHttpLoaderModule.forRoot(),
    NgxPrintModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ClientiComponent,
    ReportComponent,
    ReportManageComponent,
    ReportViewComponent,
    ReportModComponent,
    NuovoOperatoreComponent,
    UserProfileModComponent,
    PrintReportComponent,
    PrintReportLayoutComponent,
    ReportUploadPhotoComponent,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
