import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClienteModel } from '../_model/clienti.model';

import { Config } from '../_settings/config';

@Injectable({
  providedIn: 'root'
})
export class ClientiService {

  PHP_API_SERVER = Config.apiUrl;

  constructor(
    private httpClient: HttpClient
  ) { }

  manageCliente(body) {
    console.log(body);
    return this.httpClient.post<ClienteModel>(`${this.PHP_API_SERVER}clienti_api.php`, body);
  }

  getCliente(id) {
    let body = {
      idCliente: id,
      chk: 'select_cliente'
    }
    return this.httpClient.post<ClienteModel>(`${this.PHP_API_SERVER}clienti_api.php`, body);
  }

  getListClienti(): Observable<any> {
    let body = {
      chk: 'lista_clienti'
    }
    return this.httpClient.get(`${this.PHP_API_SERVER}clienti_api.php`);
  }

}
