import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-print-report-layout',
  templateUrl: './print-report-layout.component.html',
  styleUrls: ['./print-report-layout.component.css']
})
export class PrintReportLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
