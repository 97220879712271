import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientiService } from 'src/app/_services/clienti.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() my_modal_title;
  @Input() my_modal_content;
  @Input() my_modal_content_id;
  @Input() my_modal_type_action;

  constructor(
    public activeModal: NgbActiveModal,
    public clientiService: ClientiService,
    public router: Router,
    public reportService: ReportService
  ) {}

  ngOnInit() {
    console.log(this.my_modal_type_action);
    if (this.my_modal_type_action == 'delReport') {
      console.log(this.my_modal_content_id);
      this.eliminaReport(this.my_modal_content_id);
    }

  }

  eliminaCliente(id) {
    /*console.log(id);
    let body = {
      chk: 'del_cliente',
      idCliente: id
    }

    this.activeModal.close();
    return new Promise(resolve => {
      this.clientiService.manageCliente(body).subscribe((res: any) => {
        console.log(res);
        if (res.success == true) {
          //this.router.navigate(['/clienti']);
          window.location.reload();

        } else {
          console.log(res.result);
        }
      }, (err)=> {
        console.log(err);
      });
    });*/
  }


  eliminaReport(id) {

    this.activeModal.close();
    return new Promise(resolve => {
      this.reportService.eliminaReport(id).subscribe((res: any) => {
        console.log(res);
        if (res.success == true) {
          //this.router.navigate(['/clienti']);
          window.location.reload();

        } else {
          console.log(res.result);
        }
      }, (err)=> {
        console.log(err);
      });
    });
  }


}
