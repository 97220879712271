import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientiService } from 'src/app/_services/clienti.service';
import { ClienteModel } from 'src/app/_model/clienti.model';
import { ReportService } from 'src/app/_services/report.service';
import { formatDate } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { androidpublisher_v1 } from 'googleapis';

//const { addMinutes } = require('date-fns');
/*import { google, GoogleApis } from 'googleapis';
declare var gapi: any;

const scopes = ['https://www.googleapis.com/auth/calendar/uim172tcm794098idvflqlupn8@group.calendar.google.com/events?key=AIzaSyAletHf8JIZn_2Y0IHy1UMeQkTe1h9bEnk'];

const client = google.auth.GoogleAuth({
  keyFile: 'api/ecoclienti-b35bfbedcc8a.json',
  scopes,
})

//client.subject = 'gestioneclientieco@gmail.com';
const calendar = google.calendar({ version: 'v3', auth: client });
*/

@Component({
  selector: 'app-report-manage',
  templateUrl: './report-manage.component.html',
  styleUrls: ['./report-manage.component.css']
})
export class ReportManageComponent implements OnInit {

  todayDate = new Date().toISOString();
  todayDateTemp = this.todayDate.split('T');
  localID: string;

  idReport: any;

  idCliente: number;
  nomeCliente: string = '';
  cognomeCliente: string = '';
  emailCliente: string = '';
  telefonoCliente: string = '';
  noteCliente: string = '';
  dataIng: any;
  alimentatore: number = 0;
  altro: string = '';
  borsa: number = 0;
  cavoAlimentazione: number = 0;
  cavoUsb: number = 0;
  chiavettaUsb: number = 0;
  numRifInt: any;
  password: string = '';
  problema: string = '';
  tipoMacchina: string = '';
  varie: string = '';
  preventivo: string = '';
  tecnico: any;
  dataConsegna: any;
  oraConsegna: any;
  slotUno: any;
  //slotDue: any;
  //slot1: any; //slot più grande da selezionare manualmente
  //slot23: any; //slot più grande da selezionare manualmente

  //slotUnoTemp: any; // prende il valore dello slot uno selezionato per disabilitarlo nella scelta dello slot due
  //slotDueTemp: any;
  //slot1Temp: any;
  //slot23Temp: any;

  //slot1Open: any; // controllo se slot disponibile
  //slot23Open: any; // controllo se slot disponibile

  modoInvio: string; // modifica o aggiungi report

  dataConsegnaCheck: boolean;

  //datiReport = new ReportModel;

  datiReport: any;

  reportForm: UntypedFormGroup;
  addUserForm: UntypedFormGroup;
  // Flag to check if form submitted by user to handle error messages
  isFormSubmitted = false;
  submittedForm = false;

  slotList: any;

  currentTime: any;
  currentDate: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    public router: Router,
    private clientiService: ClientiService,
    private fb: UntypedFormBuilder,
    private reportService: ReportService,
    @Inject( LOCALE_ID ) localID: string,
  ) {

    this.localID = localID;

    this.activatedRoute.queryParams
    .subscribe(params => {
      console.log(params);
      this.modoInvio = params.modo;
      this.idCliente = params.idCliente;
      this.getDatiCliente(this.idCliente);
      //this.listaSlot();
      //this.dataIng = formatDate(this.todayDate,'yyyy-MM-dd', this.localID);
      this.dataIng = this.todayDateTemp[0];

    });


  }

  ngOnInit() {
    console.log(this.todayDate);

    let today = new Date();
    let hours, minutes;

    if (today.getHours() <10) {
      hours = '0' + today.getHours();
    } else {
      hours = today.getHours();
    }
    
    if (today.getMinutes() <10) {
      minutes = '0' + today.getMinutes();
    } else {
      minutes = today.getMinutes();
    }

    this.currentTime = hours + ":" + minutes;
    this.currentDate = this.todayDate.split('T');

    console.log(this.currentDate[0]);

    this.reportForm = this.fb.group({
      dataIngresso: [this.dataIng],
      numRifInt: [this.numRifInt],
      password: [this.password],
      tipoMacchina: [this.tipoMacchina],
      alimentatore: [this.alimentatore],
      borsa: [this.borsa],
      cavoAlimentazione: [this.cavoAlimentazione],
      cavoUsb: [this.cavoUsb],
      chiavettaUsb: [this.chiavettaUsb],
      altro: [this.altro],
      varie: [this.varie],
      preventivo: [this.preventivo],
      tecnico: [this.tecnico],
      problema: [this.problema],
      dataConsegna: [this.currentDate[0], [
        Validators.required
      ]],
      oraConsegna: [this.currentTime, [
        Validators.required
      ]],
      slotUno: [this.slotUno],
      //slotDue: [this.slotDue],
      //slot1: [this.slot1],
      //slot23: [this.slot23]
    });
  }

  getDatiCliente(id) {
    this.clientiService.getCliente(id).subscribe((res: any) => {
      //console.log(res.result);
      //console.log(this.idCliente);
      this.nomeCliente = res.result.nome_cliente;
      this.cognomeCliente = res.result.cognome_cliente;
      this.telefonoCliente = res.result.telefono_cliente;
      this.emailCliente = res.result.email_cliente;
      this.noteCliente = res.result.note_cliente;

      // Defining Form Controlls with initial value and validations for each form control



      //console.log(this.reportForm);
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.reportForm.controls; }

  onSubmit() {
    let slotU, slotD;
    console.log(this.reportForm.value);
    console.log(this.reportForm.controls.dataConsegna.value);

    let dataConsegnaDef: any = 'empty';

    //console.log(this.reportForm);
    this.submittedForm = true;

    this.isFormSubmitted = true;

    // Return if form is invalid
    if (this.reportForm.invalid) {
      return;
    }
    if (this.reportForm.value.slotUno == '') { //&& this.reportForm.value.slotDue == '' && this.reportForm.value.slot1 == false && this.reportForm.value.slot23 == false) {
      return;
    }
    console.log('Submit', this.reportForm.value.dataConsegna);

    if (this.reportForm.value.dataConsegna != null) {
      dataConsegnaDef = new Date(this.reportForm.value.dataConsegna + 'T10:00:00+01:00').toISOString();
    }
    
    let body = {
      chk: 'add_report',
      idCliente: this.idCliente,
      dataIngresso: this.todayDateTemp[0],
      alimentatore: this.reportForm.value.alimentatore,
      altro: this.reportForm.value.altro,
      borsa: this.reportForm.value.borsa,
      cavoAlimentazione: this.reportForm.value.cavoAlimentazione,
      cavoUsb: this.reportForm.value.cavoUsb,
      chiavettaUsb: this.reportForm.value.chiavettaUsb,
      numRifInt: this.reportForm.value.numRifInt,
      password: this.reportForm.value.password,
      problema: this.reportForm.value.problema,
      tipoMacchina: this.reportForm.value.tipoMacchina,
      varie: this.reportForm.value.varie,
      preventivo: this.reportForm.value.preventivo,
      tecnico: this.reportForm.value.tecnico,
      dataConsegna: this.reportForm.value.dataConsegna,
      oraConsegna: this.reportForm.value.oraConsegna,
      slotUno: this.reportForm.value.slotUno,
      //slotDue: this.reportForm.value.slotDue,
      //slot1: this.reportForm.value.slot1,
      //slot23: this.reportForm.value.slot23
    }
      console.log(body);
    return new Promise(resolve => {

      this.reportService.manageReport(body).subscribe((res: any) => {
        console.log(res);

        if (res.success == true) {
          let idReport = res.result.split('-');
          console.log(res.result);
          //this.printDiv(this.reportForm.value);
          //this.printDiv(this.reportForm.value, this.nomeCliente, this.cognomeCliente,this.telefonoCliente, this.emailCliente);
          // CREARE EVENTO CALENDAR
          this.addEvent(this.reportForm.value, idReport[1]);
          //
        } else {
          console.log(res.result);
        }
      }, (err)=> {
        console.log(err);
      });
    });

  }



  addEvent(values, idReport) {
    console.log(values);

    this.reportService.addCalendar(this.nomeCliente,this.cognomeCliente,values.tipoMacchina,values.dataConsegna, values.oraConsegna, idReport).subscribe((res:any) => {
      console.log(res);
      //this.printDiv(values);
      this.router.navigate(['/report']);
    },
    error => {
      console.log(error);
    });
  }

  /*printDiv(values) {

    let dataIng = formatDate(values.dataIngresso,'dd/MM/yyyy', this.localID);
    let alimentatore, borsa, cavoAli, cavoUsb, chiavettaUsb;
    if (values.alimentatore == true) { alimentatore = 'checked'} else { alimentatore = '' }
    if (values.borsa == true) { borsa = 'checked'} else { borsa = '' }
    if (values.cavoAli == true) { cavoAli = 'checked'} else { cavoAli = '' }
    if (values.cavoUsb == true) { cavoUsb = 'checked'} else { cavoUsb = '' }
    if (values.chiavettaUsb == true) { chiavettaUsb = 'checked'} else { chiavettaUsb = '' }
    let divToPrint = document.getElementById('report-print');
    let newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write(`
    <html>
    <head>
    <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css" media="print"/>
    <style>
      body {
        margin: 10px !important;
        padding: 10px !important;
      }
      @page { size: A4 portrait }</style>
    </head>
    <body onload="window.print()" class="A5 portrait">
      <div class="form-group row">
          <div class="col-3">
              <label class="form-control-label" for="input-nome">Data ingresso</label><br>
              <span>${dataIng}</span>
          </div>
          <div class="col-6 text-center" style="border: 1px solid black !important;">
              <strong>ECORIGENERA</strong>
              <br>
              SCHEDA DI INGRESSO/RIPARAZIONE
          </div>
          <div class="col-3">
              &nbsp;
          </div>
      </div>
      <!-- Name control -->
      <div class="form-group row">

          <div class="col-2">
              <label class="col-form-label">Num. Rif. Interno</label><br>
              <input type="text" formControlName="numRifInt" class="form-control" value="${values.numRifInt}">
          </div>

          <div class="col-5">
              <label class="col-form-label">Password</label><br>
              <input type="text" formControlName="password" class="form-control" value="${values.password}">
          </div>

          <div class="col-5">
              <label class="col-form-label">Tipo macchina</label><br>
              <input type="text" formControlName="tipoMacchina" class="form-control" value="${values.tipoMacchina}">
          </div>
      </div>

      <div class="form-group row">
          <div class="col-12">
              <div class="row">
                  <div class="col-12 text-center">
                      <strong>CLIENTE</strong>
                  </div>
              </div>
              <br>
              <div class="row">
                  <div class="col-3">
                      <div class="form-group">
                      <label class="form-control-label" for="input-nome">Nome</label><br>
                      <input type="text" readonly id="input-nome" class="form-control form-control-alternative" formControlName="nomeCliente" name="nomeCliente" value="${this.nomeCliente}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-cognome">Cognome</label><br>
                          <input type="text" readonly id="input-cognome" class="form-control form-control-alternative" formControlName="cognomeCliente" value="${this.cognomeCliente}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-email">Email</label><br>
                          <input type="email" readonly id="input-email" class="form-control form-control-alternative" formControlName="emailCliente" value="${this.emailCliente}">
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          <label class="form-control-label" for="input-telefono">Telefono</label><br>
                          <input type="text" readonly id="input-telefono" class="form-control form-control-alternative" formControlName="telefonoCliente" value="${this.telefonoCliente}">
                      </div>
                      </div>
              </div>
          </div>
      </div>

      <div class="form-group row">
          <div class="col-10 offset-1 text-center" style="border: 2px solid black;">
              <div class="row">
                  <div class="col-12 text-center">
                      <strong>ACCESSORI</strong>
                  </div>
              </div>
              <br>
              <div class="row justify-content-center">
                  <div class="col-2">
                  <div class="form-group">
                      Alimentatore&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="alimentatore" ${alimentatore}>
                  </div>
                  </div>
                  <div class="col-2">
                  <div class="form-group">
                      Borsa&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="borsa" ${borsa}>
                  </div>
                  </div>
                  <div class="col-3">
                  <div class="form-group">
                      Cavo alimentazione&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoAlimentazione" ${cavoAli}>
                  </div>
                  </div>
                  <div class="col-2">
                  <div class="form-group">
                      Cavo USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoUsb" ${cavoUsb}>
                  </div>
                  </div>
                  <div class="col-3">
                  <div class="form-group">
                      Chiavetta USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="chiavettaUsb" ${cavoUsb}>
                  </div>
                  </div>
              </div>
              <div class="row">
                  <div class="col-6">
                  <div class="form-group">
                      <label class="form-control-label" for="input-altro">Altro</label>
                      <input type="text" id="input-altro" class="form-control form-control-alternative" formControlName="altro" value="${values.altro}">
                  </div>
                  </div>
                  <div class="col-6">
                  <div class="form-group">
                      <label class="form-control-label" for="input-varie">Varie</label>
                      <input type="text" id="input-varie" class="form-control form-control-alternative" formControlName="varie" value="${values.varie}">
                  </div>
                  </div>
              </div>
          </div>
      </div>

      <div class="form-group row">
          <div class="col-12">
          <div class="form-group">
              <label class="form-control-label" for="input-problema">Problema riscontrato</label>
              <textarea class="form-control" formControlName="problema" rows="3">${values.problema}</textarea>
          </div>
          </div>
      </div>
    </body></html>`);
    console.log("POPOPOPOPO");
    newWin.document.close();

    setTimeout(function() {
      //window.location.reload();
      newWin.close();
    }, 3);

  }*/

  printDiv(values, nome, cognome, telefono, email) {
    console.log(values);
    console.log('DUE ' + this.dataIng);
        let dataIng = formatDate(this.dataIng,'dd/MM/yyyy', this.localID);
        ///let data_temp = this.dataIng.split('-');
        //let dataIng = data_temp[2] + '/' + data_temp[1] + '/' + data_temp[0];
        let alimentatore, borsa, cavoAli, cavoUsb, chiavettaUsb;
        if (values.alimentatore == true) { alimentatore = 'checked'} else { alimentatore = '' }
        if (values.borsa == true) { borsa = 'checked'} else { borsa = '' }
        if (values.cavoAlimentazione == true) { cavoAli = 'checked'} else { cavoAli = '' }
        if (values.cavoUsb == true) { cavoUsb = 'checked'} else { cavoUsb = '' }
        if (values.chiavettaUsb == true) { chiavettaUsb = 'checked'} else { chiavettaUsb = '' }
        let divToPrint = document.getElementById('report-print');
        let newWin = window.open('', 'Print-Window');
        newWin.document.open();
        newWin.document.write(`
        <html>
        <head>
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css" media="print"/>
        <style>
          body {
            margin: 10px !important;
            padding: 10px !important;
          }
          @page { size: A4 portrait }
        </style>
        </head>
        <body onload="window.print()" class="A5 portrait">
          <div class="form-group row">
              <div class="col-3">
                  <label class="form-control-label" for="input-nome">Data ingresso</label><br>
                  <span>${dataIng}</span>
              </div>
              <div class="col-6 text-center" style="border: 1px solid black;">
                  <strong>ECORIGENERA</strong>
                  <br>
                  SCHEDA DI INGRESSO/RIPARAZIONE
              </div>
              <div class="col-3">
                  &nbsp;
              </div>
          </div>
          <!-- Name control -->
          <div class="form-group row">

              <div class="col-2">
                  <label class="col-form-label">Num. Rif. Interno</label><br>
                  <input type="text" formControlName="numRifInt" class="form-control" value="${values.numRifInt}">
              </div>

              <div class="col-5">
                  <label class="col-form-label">Password</label><br>
                  <input type="text" formControlName="password" class="form-control" value="${values.password}">
              </div>

              <div class="col-5">
                  <label class="col-form-label">Tipo macchina</label><br>
                  <input type="text" formControlName="tipoMacchina" class="form-control" value="${values.tipoMacchina}">
              </div>
          </div>

          <div class="form-group row">
              <div class="col-12">
                  <div class="row">
                      <div class="col-12 text-center">
                          <strong>CLIENTE</strong>
                      </div>
                  </div>
                  <br>
                  <div class="row">
                      <div class="col-3">
                          <div class="form-group">
                          <label class="form-control-label" for="input-nome">Nome</label><br>
                          <input type="text" readonly id="input-nome" class="form-control form-control-alternative" formControlName="nomeCliente" name="nomeCliente" value="${nome}">
                          </div>
                          </div>
                          <div class="col-3">
                          <div class="form-group">
                              <label class="form-control-label" for="input-cognome">Cognome</label><br>
                              <input type="text" readonly id="input-cognome" class="form-control form-control-alternative" formControlName="cognomeCliente" value="${cognome}">
                          </div>
                          </div>
                          <div class="col-3">
                          <div class="form-group">
                              <label class="form-control-label" for="input-email">Email</label><br>
                              <input type="email" readonly id="input-email" class="form-control form-control-alternative" formControlName="emailCliente" value="${email}">
                          </div>
                          </div>
                          <div class="col-3">
                          <div class="form-group">
                              <label class="form-control-label" for="input-telefono">Telefono</label><br>
                              <input type="text" readonly id="input-telefono" class="form-control form-control-alternative" formControlName="telefonoCliente" value="${telefono}">
                          </div>
                          </div>
                  </div>
              </div>
          </div>

          <div class="form-group row">
              <div class="col-10 offset-1 text-center" style="border: 2px solid black;">
                  <div class="row">
                      <div class="col-12 text-center">
                          <strong>ACCESSORI</strong>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-center">
                      <div class="col-2">
                      <div class="form-group">
                          Alimentatore<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="alimentatore" ${alimentatore}>
                      </div>
                      </div>
                      <div class="col-2">
                      <div class="form-group">
                          Borsa&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="borsa" ${borsa}>
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          Cavo alimentazione&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoAlimentazione" ${cavoAli}>
                      </div>
                      </div>
                      <div class="col-2">
                      <div class="form-group">
                          Cavo USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoUsb" ${cavoUsb}>
                      </div>
                      </div>
                      <div class="col-3">
                      <div class="form-group">
                          Chiavetta USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="chiavettaUsb" ${chiavettaUsb}>
                      </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-6">
                      <div class="form-group">
                          <label class="form-control-label" for="input-altro">Altro</label>
                          <input type="text" id="input-altro" class="form-control form-control-alternative" formControlName="altro" value="${values.altro}">
                      </div>
                      </div>
                      <div class="col-6">
                      <div class="form-group">
                          <label class="form-control-label" for="input-varie">Varie</label>
                          <input type="text" id="input-varie" class="form-control form-control-alternative" formControlName="varie" value="${values.varie}">
                      </div>
                      </div>
                  </div>
              </div>
          </div>

          <div class="form-group row">
              <div class="col-12">
              <div class="form-group">
                  <label class="form-control-label" for="input-problema">Problema riscontrato</label>
                  <textarea class="form-control" formControlName="problema" rows="3">${values.problema}</textarea>
              </div>
              </div>
          </div>
        </body></html>`);
        newWin.document.close();


        //
        setTimeout(function() {
          // window.location.reload();
          console.log("OK");
          newWin.close();
        }, 30);
      }

  /*listaSlot() {
    this.reportService.listaSlot().subscribe((res: any) => {
      console.log("SLOT: ",res);
      this.slotList = res.result;
      this.slot1Open = res.slot1;
      this.slot23Open = res.slot23;
      // se comincerà ad usare il pulsante "consegnato" eliminare l'if ed inserire: this.slotUno = res.result.num_slot
      // inoltre nel file report_api.php commentare riga 451 e togliere commento riga 450 per ripristinare il metodo precedente
      if (res.result.num_slot < 44 ) {
        this.slotUno = Number(res.result.num_slot) + 1;
      } else {
        this.slotUno = 2;
      }
      console.log("SLOT UNO",this.slotUno);
    });
  }*/

}
