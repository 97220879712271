import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { from, Observable } from 'rxjs';
import { ClienteModel } from '../_model/clienti.model';
import { ReportModel } from '../_model/report.model';

import { Config } from '../_settings/config';



@Injectable({
  providedIn: 'root'
})
export class ReportService {

  PHP_API_SERVER = Config.apiUrl;

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) {
    console.log(this.PHP_API_SERVER);
  }

  manageReport(body) {
    console.log(body);
    //this.router.navigate(['/report']);
    return this.httpClient.post<ReportModel>(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  addCalendar(nome,cognome,macchina,data,ora, idReport) {
    let oraTemp = ora.split(':');
    let oraTempUno = oraTemp[0] -1;
    let oraDef = oraTempUno + ':' + oraTemp[1];
    let body = {
      nome: nome,
      cognome: cognome,
      macchina: macchina,
      data: data,
      ora: oraDef,
      idReport: idReport
    };

    return this.httpClient.post<ReportModel>(`${this.PHP_API_SERVER}calendar_api.php`, body);

  }


  modCalendar(nome,cognome,macchina,data,ora,idEvento, idReport) {
    let oraTemp = ora.split(':');
    let oraTempUno = oraTemp[0] -1;
    let oraDef = oraTempUno + ':' + oraTemp[1];
    let body = {
      nome: nome,
      cognome: cognome,
      macchina: macchina,
      data: data,
      ora: oraDef,
      idReport: idReport,
      idEvento: idEvento
    };

    return this.httpClient.post<ReportModel>(`${this.PHP_API_SERVER}calendar_mod_api.php`, body);

  }

  getListReport() {
    let body = {
      chk: 'lista_report'
    }
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  getListReportInLavorazione() {
    let body = {
      chk: 'lista_report_in_lavorazione'
    }
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  visualizzaReport(id) {
    let body = {
      chk: 'view_report',
      id: id
    }
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  getSingleReport(id) {
    let body = {
      chk: 'report_singolo',
      idReport: id
    }
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  reportPronto(id) {
    let body = {
      chk: 'report_pronto',
      idReport: id
    }
    //this.router.navigate(['/dashboard']).then(() => {
      window.location.reload();
    //});

    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  consegnaReport(id) {
    let body = {
      chk: 'report_consegna',
      idReport: id
    }
    this.router.navigate(['/report']);
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }

  eliminaReport(id) {
    let body = {
      chk: 'del_report',
      idReport: id
    }
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);
  }


  printDiv(id) {
    /*let divToPrint = document.getElementById('report-print');
    let newWin = window.open('', 'Print-Window');
    newWin.document.open();
    newWin.document.write('<html><head><link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css" media="print"/><style>@page { size: A5 landscape }</style></head><body onload="window.print()" class="A5 landscape">' + divToPrint.innerHTML + '</body></html>');
    newWin.document.close();
    setTimeout(function() {
      newWin.close();
    }, 10);*/

    this.getSingleReport(id).subscribe((res:any) => {
      console.log(res.result[0]);

      let dataIngTemp = res.result[0].data_ingresso.split('-');
      let dataIng = dataIngTemp[2] + '-' + dataIngTemp[1] + ' ' + dataIngTemp[0];
      console.log(dataIng);
      ///let data_temp = this.dataIng.split('-');
      //let dataIng = data_temp[2] + '/' + data_temp[1] + '/' + data_temp[0];
      let alimentatore, borsa, cavoAli, cavoUsb, chiavettaUsb;
      if (res.result[0].acc_alimentatore == true) { alimentatore = 'checked'} else { alimentatore = '' }
      if (res.result[0].acc_borsa == true) { borsa = 'checked'} else { borsa = '' }
      if (res.result[0].acc_cavo_alim == true) { cavoAli = 'checked'} else { cavoAli = '' }
      if (res.result[0].acc_cavo_usb == true) { cavoUsb = 'checked'} else { cavoUsb = '' }
      if (res.result[0].acc_chiavetta_usb == true) { chiavettaUsb = 'checked'} else { chiavettaUsb = '' }
      let divToPrint = document.getElementById('report-print');
      let newWin = window.open('', 'Print-Window');
      newWin.document.open();
      newWin.document.write(`
      <html>
      <head>
      <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.3.1/css/bootstrap.min.css" media="print"/>
      <style>
        body {
          margin: 10px !important;
          padding: 10px !important;
        }
        @page { size: A4 portrait }
      </style>
      </head>
      <body onload="window.print()" class="A5 portrait">
        <div class="form-group row">
            <div class="col-3">
                <label class="form-control-label" for="input-nome">Data ingresso</label><br>
                <span>${dataIng}</span>
            </div>
            <div class="col-6 text-center" style="border: 1px solid black;">
                <strong>ECORIGENERA</strong>
                <br>
                SCHEDA DI INGRESSO/RIPARAZIONE
            </div>
            <div class="col-3">
                &nbsp;
            </div>
        </div>
        <!-- Name control -->
        <div class="form-group row">

            <div class="col-2">
                <label class="col-form-label">Num. Rif. Interno</label><br>
                <input type="text" formControlName="numRifInt" class="form-control" value="${res.result[0].num_rif}">
            </div>

            <div class="col-5">
                <label class="col-form-label">Password</label><br>
                <input type="text" formControlName="password" class="form-control" value="${res.result[0].password}">
            </div>

            <div class="col-5">
                <label class="col-form-label">Tipo macchina</label><br>
                <input type="text" formControlName="tipoMacchina" class="form-control" value="${res.result[0].tipo_macchina}">
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12">
                <div class="row">
                    <div class="col-12 text-center">
                        <strong>CLIENTE</strong>
                    </div>
                </div>
                <br>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group">
                        <label class="form-control-label" for="input-nome">Nome</label><br>
                        <input type="text" readonly id="input-nome" class="form-control form-control-alternative" formControlName="nomeCliente" name="nomeCliente" value="${res.result[0].nome_cliente}">
                        </div>
                        </div>
                        <div class="col-3">
                        <div class="form-group">
                            <label class="form-control-label" for="input-cognome">Cognome</label><br>
                            <input type="text" readonly id="input-cognome" class="form-control form-control-alternative" formControlName="cognomeCliente" value="${res.result[0].cognome_cliente}">
                        </div>
                        </div>
                        <div class="col-3">
                        <div class="form-group">
                            <label class="form-control-label" for="input-email">Email</label><br>
                            <input type="email" readonly id="input-email" class="form-control form-control-alternative" formControlName="emailCliente" value="${res.result[0].email_cliente}">
                        </div>
                        </div>
                        <div class="col-3">
                        <div class="form-group">
                            <label class="form-control-label" for="input-telefono">Telefono</label><br>
                            <input type="text" readonly id="input-telefono" class="form-control form-control-alternative" formControlName="telefonoCliente" value="${res.result[0].telefono_cliente}">
                        </div>
                        </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-10 offset-1 text-center" style="border: 2px solid black;">
                <div class="row">
                    <div class="col-12 text-center">
                        <strong>ACCESSORI</strong>
                    </div>
                </div>
                <br>
                <div class="row justify-content-center">
                    <div class="col-2">
                    <div class="form-group">
                        Alimentatore<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="alimentatore" ${alimentatore}>
                    </div>
                    </div>
                    <div class="col-2">
                    <div class="form-group">
                        Borsa&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="borsa" ${borsa}>
                    </div>
                    </div>
                    <div class="col-3">
                    <div class="form-group">
                        Cavo alimentazione&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoAlimentazione" ${cavoAli}>
                    </div>
                    </div>
                    <div class="col-2">
                    <div class="form-group">
                        Cavo USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="cavoUsb" ${cavoUsb}>
                    </div>
                    </div>
                    <div class="col-3">
                    <div class="form-group">
                        Chiavetta USB&nbsp;<input type="checkbox" id="input-accessori" class=" form-control-alternative" formControlName="chiavettaUsb" ${chiavettaUsb}>
                    </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label" for="input-altro">Altro</label>
                        <input type="text" id="input-altro" class="form-control form-control-alternative" formControlName="altro" value="${res.result[0].acc_altro}">
                    </div>
                    </div>
                    <div class="col-6">
                    <div class="form-group">
                        <label class="form-control-label" for="input-varie">Varie</label>
                        <input type="text" id="input-varie" class="form-control form-control-alternative" formControlName="varie" value="${res.result[0].acc_varie}">
                    </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <div class="col-12">
            <div class="form-group">
                <label class="form-control-label" for="input-problema">Problema riscontrato</label>
                <textarea class="form-control" formControlName="problema" rows="3">${res.result[0].problema}</textarea>
            </div>
            </div>
        </div>
      </body></html>`);
      newWin.document.close();


      //
      setTimeout(function() {
        //window.location.reload();
        console.log("OK");
        newWin.close();
      }, 30);

    });

  }

  listaSlot() {
    
    let body = {
      chk: 'lista_slot'
    }
    
    return this.httpClient.post(`${this.PHP_API_SERVER}report_api.php`, body);

  }


}
