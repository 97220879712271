import { Injectable, Output, EventEmitter } from '@angular/core';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Config } from '../_settings/config';
 

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  /*redirectUrl: string;
 
  baseUrl:string = Config.apiUrl;

@Output() getLoggedInName: EventEmitter<any> = new EventEmitter();

  constructor(
    private httpClient : HttpClient
  ) { }
  
  public userlogin(username, password) {
    return this.httpClient.post<any>(this.baseUrl + 'login.php', { username, password })
        .pipe(map(Usermodule => {
            console.log(Usermodule);
            this.setToken(Usermodule.result.email, Usermodule.result.ruolo);
            this.getLoggedInName.emit(true);
            return Usermodule;
        }));
  }

  public userregistration(body) {
    return this.httpClient.post(this.baseUrl + 'login_api.php', body);
  }

  public getDataDb(body, file) {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    });
    let options = {
        headers: headers
    }

    return this.httpClient.post(this.baseUrl + file, JSON.stringify(body), options)
    .pipe(map(res => {
      console.log(res);
      return res;
    }));
  }

  //token
  setToken(token: string, ruolo: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('ruolo', ruolo);
  }
  
  getToken() {
    return localStorage.getItem('token');
  }
  
  getRuolo() {
    return localStorage.getItem('token');
  }
  
  deleteToken() {
    localStorage.removeItem('token');
    localStorage.removeItem('ruolo');
  }
  
  isLoggedIn() {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true;
    }
    return false;
  }
 
}*/


  redirectUrl: string;
  
  baseUrl:string = Config.apiUrl;
  
  @Output() getLoggedInName: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private httpClient : HttpClient
  ) { }
  
  public userlogin(chk, email, password) {
    //return this.httpClient.post(`${this.baseUrl}login_api.php`, body);
    return this.httpClient.post<any>(this.baseUrl + 'login_api.php', { chk, email, password })
    .pipe(map(Users => {
      console.log(Users.result);
      this.setToken(Users.result.email);
      this.getLoggedInName.emit(true);
      return Users;
    }));
  }

  public userregistration(chk, email, password) {
    return this.httpClient.post<any>(this.baseUrl + 'login_api.php', { chk, email, password })
    .pipe(map(Users => {
      return Users;
    }));
  }

  //token
  setToken(token: string) {
    localStorage.setItem('token', token);
  }
  getToken() {
    return localStorage.getItem('token');
  }
  deleteToken() {
    localStorage.removeItem('token');
  }
  isLoggedIn() {
    const usertoken = this.getToken();
    if (usertoken != null) {
      return true
    }
    return false;
  }
}