import { Component } from '@angular/core';
import { LoginService } from './_services/login.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title: "ECORIGENERA";
  loginbtn:boolean;
  logoutbtn:boolean;
  
  constructor(private dataService: LoginService) {
    dataService.getLoggedInName.subscribe(name => this.changeName(name));

    if(this.dataService.isLoggedIn()) {
      console.log("loggedin");
      this.loginbtn=false;
      this.logoutbtn=true
    } else{
      this.loginbtn=true;
      this.logoutbtn=false
    }
  
  }
  
  private changeName(name: boolean): void {
    this.logoutbtn = name;
    this.loginbtn = !name;
  }

  logout() {
    this.dataService.deleteToken();
    window.location.href = window.location.href;
  }
}
